import { UserProfile } from "@auth0/nextjs-auth0/client";
import axios from "axios";
import Link from "next/link";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Input } from "../shared/form/input";

interface FormValues {
  givenName: string;
  familyName: string;
  emailAddress: string;
  phoneNumberAreaCode: string;
  phoneNumber: string;
  license: string;
}

interface Props {
  user: UserProfile;
}

export function HomeCreateDoctorForm({ user }: Props) {
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      givenName: (user?.given_name as string) || "",
      familyName: (user?.family_name as string) || "",
      emailAddress: user?.email || "",
    },
  });
  const createDoctorAccount = async (data: FormValues) => {
    const response = await axios.post("/api/doctors/landing-create", data);

    if (response?.data?.error) {
      toast.error(response?.data?.error);
    } else if (response?.data?.id) {
      toast.success("Cuenta creada, bienvenido.");
      router.push(`/doctors/patients`);
    }
  };

  return (
    <section className="space-y-4 py-4">
      <p>No hemos encontrado un usuario con el correo {user.email}.</p>
      <p>Si lo desea, puede registrarse como médico.</p>

      <form
        className="grid grid-cols-1 gap-6"
        onSubmit={handleSubmit(createDoctorAccount)}
      >
        <Input
          label="Correo electrónico"
          type="email"
          readOnly
          {...register("emailAddress", { required: true })}
          error={!!errors?.emailAddress}
        />
        <Input
          label="Nombre"
          {...register("givenName", { required: true })}
          error={!!errors?.givenName}
        />
        <Input
          label="Apellido"
          {...register("familyName", { required: true })}
          error={!!errors?.familyName}
        />
        <div className="grid grid-cols-1 md:grid-cols-[1fr_2fr] md:gap-2">
          <Input
            type="tel"
            label="Cod. Área"
            defaultValue="+549"
            {...register("phoneNumberAreaCode")}
          />
          <Input
            type="tel"
            label="Número"
            placeholder="Sólo números. Ej. 1122223333"
            {...register("phoneNumber")}
          />
        </div>
        <Input
          label="Matrícula"
          {...register("license", { required: true })}
          error={!!errors?.license}
        />

        <p>
          Al continua y crear una cuenta como médico acepta nuestros{` `}
          <Link href="/doctors/terms" className="hover:text-light underline">
            términos y condiciones
          </Link>
          {` y con nuestra `}
          <Link href="/doctors/privacy" className="hover:text-light underline">
            política de privacidad
          </Link>
          . Asi como asegurar que los datos suministrados son correctos.
        </p>

        <button
          type="submit"
          disabled={isSubmitting}
          className="rounded-xl bg-gray-800 px-4 py-2 font-semibold text-white disabled:bg-gray-500"
        >
          {isSubmitting ? "Creando..." : "Crear cuenta de médico"}
        </button>

        <Link href="/api/auth/logout" className="hover:text-light underline">
          Salir
        </Link>
      </form>
    </section>
  );
}
