import { getUser } from "@app/utils/get-user";
import { HomeCreateDoctorForm } from "@app/components/home/form";
import { useUser } from "@auth0/nextjs-auth0/client";
import { Role } from "@prisma/client";
import type { GetServerSideProps, NextPage } from "next";
import Image from "next/image";
import Link from "next/link";

const Home: NextPage = () => {
  const { user } = useUser();

  return (
    <div className="mx-auto grid min-h-screen grid-rows-[1fr_auto]">
      <div className="mx-auto flex max-w-md flex-col space-y-4 p-4">
        <div className="mx-auto">
          <Image
            src="/logo-transparent.png"
            alt="Logo"
            width={200}
            height={157}
          />
        </div>
        {user ? (
          <HomeCreateDoctorForm user={user} />
        ) : (
          <Link
            href="/api/auth/login"
            className="mx-auto inline-block bg-gray-800 px-8 py-4 text-center text-white"
          >
            Ingresar
          </Link>
        )}
      </div>
      <footer className="flex items-center border-t border-gray-300 py-2 text-xs">
        <p className="mx-auto">
          Anticoagul.ar | Desarrollado por
          <a href="https://www.grupocaht.com/" target="_blank" rel="noreferrer">
            <Image
              src="/logo-caht.jpeg"
              alt="Logo CAHT"
              width={87}
              height={20}
              className="mx-2 inline-block"
            />
          </a>
        </p>
      </footer>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const user = await getUser(context);
  if (user?.role === Role.MD) {
    return {
      redirect: {
        destination: "/doctors",
        permanent: false,
      },
    };
  } else if (user?.role === Role.ADMIN) {
    return {
      redirect: {
        destination: "/admin",
        permanent: false,
      },
    };
  }
  return {
    props: {},
  };
};

export default Home;
