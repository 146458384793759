import React from "react";
import { classNames } from "@app/utils/classNames";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string | boolean;
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  function InputInner(
    {
      type = "text",
      label,
      className,
      readOnly,
      required,
      error = false,
      ...restOfProps
    },
    ref
  ) {
    const calculatedClassNames = classNames(
      "mt-1 block w-full rounded-md border-gray-300 shadow-sm",
      "focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500",
      "disabled:bg-gray-200",
      "invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500", // html-based validation
      error
        ? "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500"
        : "", // prop-based validation
      readOnly ? "bg-gray-200" : "",
      className || ""
    );

    // on number inputs, disable wheel to change value
    let onwheel = undefined;
    if (type === "number") {
      onwheel = (e: React.WheelEvent<HTMLInputElement>) => {
        e.currentTarget.blur();
      };
    }

    return (
      <label className="block">
        {label && (
          <span className="text-gray-700">
            {label}
            {required && "*"}
          </span>
        )}
        <input
          type={type}
          className={calculatedClassNames}
          readOnly={readOnly}
          required={required}
          {...restOfProps}
          ref={ref}
          onWheel={onwheel}
        />
      </label>
    );
  }
);
